import '../css/theme.scss';
import $ from 'jquery';
import Simulation from './components/simulation';
import DemandeForm from './components/form';
import Faqlist from './components/faqlist';
import Account from './components/account';
import Map from './components/map';

class Site {
    constructor () {
        this.jQuery = $;
        this.trigger();
        this.callback();
        this.mobile_menu = $(window).width() <= 992;
        this.menuParent = $('.menu-main-container').parent();
        this.menuClone = this.menuParent.clone();

        let lastHash = '';
        const cssFileUrl = 'http://192.168.0.178/local.deltacredit.be/wp-content/themes/eyaka/assets/css/theme.css'; // Remplace par le chemin de ton fichier CSS
        const intervalTime = 2000; // 10 secondes

// Fonction pour obtenir un hash du contenu d'un fichier
        async function getFileHash(url) {
            const response = await fetch(url);
            const text = await response.text();

            // Utilisation de CryptoJS pour générer un hash SHA-256
            const hash = CryptoJS.SHA256(text).toString(CryptoJS.enc.Hex);

            return hash;
        }

// Fonction pour mettre à jour le fichier CSS dans le head
        function updateCSSFile() {
            // Supprimer l'élément <link> existant
            const existingLink = document.querySelector(`link[href="${cssFileUrl}"]`);
            if (existingLink) {
                existingLink.remove();
            }

            // Créer un nouvel élément <link> pour le fichier CSS
            const link = document.createElement('link');
            link.rel = 'stylesheet';
            link.href = cssFileUrl+'?v='+Date.now();
            document.head.appendChild(link);
        }

// Vérifier toutes les 10 secondes si le fichier a été modifié
        setInterval(async () => {
            const currentHash = await getFileHash(cssFileUrl);
            if (currentHash !== lastHash) {
                lastHash = currentHash;
                updateCSSFile();
            }
        }, intervalTime);
    }

    handleMenu() {
        if ($(window).width() <= 992 && !this.mobile_menu) {
            let menuParent = $('.menu-main-container').parent();
            $('.menu-main-container').appendTo('#header');
            menuParent.remove();
            this.mobile_menu = true;
        } else if ($(window).width() > 992 && this.mobile_menu) {
            $('.menu-main-container').remove();
            this.menuClone.insertAfter($('.branding').parent());
            this.menuParent = this.menuClone;
            this.menuClone = this.menuParent.clone();
            this.mobile_menu = false;
        }
    }

    addResizeEvent() {
        $(window).on('resize', () => {
            this.handleMenu(); // Appeler la logique à chaque redimensionnement
        });
    }

    trigger() {
        const $body = $('body'),
            $breadcrumbForm = $('.breadcrumbForm');

        this.handleMenu();
        this.addResizeEvent();

        if ($('select').length > 0) $("select").not('.flagstrap').chosen({disable_search_threshold: 15});

        if($body.find('[data-simulation]').length > 0) {
            new Simulation({
                element : document.querySelector('[data-simulation]'),
                datas : window.dataSim,
            });
        }

        if($body.find('.simform').length > 0) {
            new DemandeForm();
        }

        if($body.find('#map').length > 0) {
            new Map();
        }

        if($body.find('.account-wrapper').length > 0) {
            new Account();
        }

        if($body.find('[data-faqlist]').length > 0) {
            new Faqlist({
                element : document.querySelector('[data-faqlist]'),
            });
        }

        if($body.find('.breadcrumbForm').length > 0) new MultiStep();

        $("#agencyList__select").chosen().change(e => document.location = $(e.target).val());


        if($(window).width() > 980) {
            $('.nav li.menu-item-75').hover(function(e){
                $(this).find('.sub-menu').addClass('sub-menu--active');
            }, function(){
                $('.menu__item--current').removeClass('menu__item--current');
                $('.sub-menu').removeClass('sub-menu--active');
            });
        }
    }

    callback() {
        if (window.location.hash == '#wpcf7-f116-o2') {
            $('.popup').addClass('active');
        }

        const $navTrigger = $('#js-burger'),
            $closePopup = $('.popup__close'),
            $callmeback = $('.btn--callmeback'),
            $navContainer = $('.menu-main-container'),
            $navBody = $('body');

        $callmeback.on('click',(e)=>{
            e.preventDefault();
            e.stopPropagation();
            $('.popup').addClass('active');
        });

        $closePopup.on('click',(e)=>{
            e.preventDefault();
            e.stopPropagation();
            $('.popup').removeClass('active');
        });

        $('body').on('click', (e) => {
            $('.popup').removeClass('active');
        });

        $('.popup__wrap,.popup__wrap *:not(.popup__close):not(.popup__close *)').on('click', e => event.stopPropagation() );

        $navTrigger.click(e => {
            e.preventDefault();
            $navTrigger.toggleClass('active');
            $navContainer.toggleClass('active');
            $navBody.toggleClass('stop-scroll');
        });
    }
}

$(function(){
    new Site();
    if ($('.simulation__typeForm').length > 0) $('.simulation__typeForm').change();
});
import $ from 'jquery';
import 'chosen-js';
import '../vendor/jquery.flagstrap';
import '../vendor/zebra_datepicker.min';
import '../vendor/jquery.mask';

export default class DemandeForm {
    constructor() {
        this.initialize();
    }

    initialize() {
        this.checkToken();
        this.layout();
        this.select();
        this.groups();
        this.navSection();
        this.hiddenFields();
        this.flagStrapJs();
        this.checkStep();
        this.dateField();
        this.copyField();
        this.mask();
        this.resume();
        this.custom();
    }

    checkToken() {
        if (!localStorage.getItem('request_token')) {
            localStorage.setItem('request_token', this.generateToken());

            var request_token = localStorage.getItem('request_token');
            if (request_token) {
                jQuery.ajax({
                    type: "POST",
                    url: ajaxurl.ajaxurl,
                    data: {
                        action: "get_request_data",
                        request_token: request_token,
                    },
                    success: function (response) {
                        if (response.success) {
                            var form_data = response.data.form_data;
                            var current_step = response.data.current_step;

                            // Remplir les champs du formulaire
                            for (var key in form_data) {
                                var el = $('[name="' + key + '"]');
                                if (el.is(':checkbox, :radio')) {
                                    el.prop('checked', form_data[key]);
                                } else {
                                    el.val(form_data[key]);
                                }
                            }

                            // Mettre à jour l'étape dans le localStorage
                            localStorage.setItem('steps', JSON.stringify({ step: current_step }));
                        }
                    },
                    error: function (errorThrown) {
                        console.error('Erreur lors du chargement des données : ', errorThrown);
                    }
                });
            }
        }
    }

    generateToken() {
        return '_' + Math.random().toString(36).substr(2, 9) + Date.now();
    }

    resume() {
        const savedCredit = JSON.parse(localStorage.getItem('credit'));
        const defaultValues = {
            title: 'Prêt personnel',
            credits_id: '120',
            amount: '10000',
            taeg: '7.99',
            month: '60',
            monthly: '201.38',
            total: '12082.80'
        };

        const values = savedCredit || defaultValues;

        $('[data-value="type"]').text(values.title + ' ' + $('[data-value="type"]').data('str'));
        $('[name="credits_type"]').val(values.title.trim());

        $('[name="credits_id"]').val(values.credits_id);

        $('[data-value="amount"]').text(values.amount + ' ' + $('[data-value="amount"]').data('str'));
        $('[name="credits_montant"]').val(values.amount + ' €');

        $('[data-value="taeg"]').text(values.taeg + ' ' + $('[data-value="taeg"]').data('str'));
        $('[name="credits_taeg"]').val(values.taeg + ' %');

        $('[data-value="month"]').text(values.month + ' ' + $('[data-value="month"]').data('str'));
        $('[name="credits_duree"]').val(values.month + ' mois');

        $('[data-value="monthly"]').text(values.monthly + ' ' + $('[data-value="monthly"]').data('str'));
        $('[name="credits_mensualite"]').val(values.monthly + ' €/mois');

        const total = savedCredit
            ? parseFloat(savedCredit.month * savedCredit.monthly).toFixed(2)
            : defaultValues.total;

        $('[data-value="total"]').text(total + ' ' + $('[data-value="total"]').data('str'));
        $('[name="credits_total"]').val(total);
    }

    storageStep(step) {
        const arrayToStorage = {};

        $(`.simform-step-${step} .simform-elem:visible`).each(function () {
            const el = $(this).find('input:not(.chosen-search-input):not([type="radio"]), select, input[type="radio"]:checked');
            if ($(this).hasClass('simform-radio')) {
                arrayToStorage[el.attr('name')] = [el.val()];
            } else {
                arrayToStorage[el.attr('name')] = [el.val()];
            }
        });

        $(`.simform-step-${step} .form-groups`).each(function (groupIndex) {
            const currentGroup = $(this);
            arrayToStorage[`group_${groupIndex + 1}`] = {};
            currentGroup.find('.groups-elem:not(.groups-elem-template)').each(function (elemIndex) {
                const currentElem = $(this);
                arrayToStorage[`group_${groupIndex + 1}`][elemIndex] = {};
                currentElem.find('.simform-elem').each(function () {
                    const el = $(this).find('input:not(.chosen-search-input):not([type="radio"]), select, input[type="radio"]:checked');
                    if ($(this).hasClass('simform-radio')) {
                        arrayToStorage[`group_${groupIndex + 1}`][elemIndex][el.attr('name')] = [el.val()];
                    } else {
                        arrayToStorage[`group_${groupIndex + 1}`][elemIndex][el.attr('name')] = [el.val()];
                    }
                });
            });
        });

        localStorage.setItem(`form_step_${step}`, JSON.stringify(arrayToStorage));
    }

    storageFill(step) {
        var _this = this;
        var fields = localStorage.getItem('form_step_' + step);
        var step = step;
        var count_repeater = 0;
        fields = JSON.parse(fields);


        for (var key_reapeat in fields) {
            if (fields.hasOwnProperty(key_reapeat)) {
                if (!key_reapeat.indexOf('group_')) {
                    if (typeof fields[key_reapeat][0] != 'undefined') {
                        for (var grp in fields[key_reapeat]) {
                            $('.form-groups[data-group-id="' + key_reapeat + '"]>.add-groups').trigger('click');
                        }
                    }
                }
            }
        }

        for (var key in fields) {
            if (fields.hasOwnProperty(key)) {
                $('[name=' + key + ']').val(fields[key]);
                if ($('[name=' + key + ']').attr('type') == 'radio' && $('[name=' + key + '][value="Oui"]:checked').length) {
                    $('[name=' + key + ']').prop('checked', true);
                    $('[name=' + key + ']').change();
                }
                else if ($('[name=' + key + ']').hasClass('elem-select')) {
                    $('[name=' + key + ']').trigger("chosen:updated");
                }

                if ($('[name="' + key + '"]').attr('type') == 'checkbox' && fields[key][0] == 1) {
                    $('[name="' + key + '"]').prop('checked', true);
                    $('[name="' + key + '"]').val('1');
                    if (key == 'check_rgpd') {
                        $('input[name="rgpd_consentement1_mail"]').attr('value', 'Oui');
                        $('input[name="rgpd_consentement1"]').attr('value', '1');
                    }
                }

                if (key == 'emprunteur_pays') {
                    $('.emprunteur_pays').find('.flagstrap').find('a[data-val="' + fields[key] + '"]').trigger('click');
                }
                if (key == 'coemprunteur_pays') {
                    $('.coemprunteur_pays').find('.flagstrap').find('a[data-val="' + fields[key] + '"]').trigger('click');
                }
                if (key == 'emprunteur_emp_pays') {
                    $('.emprunteur_emp_pays').find('.flagstrap').find('a[data-val="' + fields[key] + '"]').trigger('click');
                }
                if (key == 'coemprunteur_emp_pays') {
                    $('.coemprunteur_emp_pays').find('.flagstrap').find('a[data-val="' + fields[key] + '"]').trigger('click');
                }
            }
        }

        $('[name*="emprunteur_revenu_montant_"],[name*="emprunteur_revenu_montant_"]').each(function () {
            var key_repeat = $(this).attr('name').replace('emprunteur_revenu_montant_', '');
            if ($('[name="emprunteur_revenu_nature_' + key_repeat + '"]').val() == 'Autres') {
                $('[name="revenu_sup1"]').val($(this).val());
            }
            if ($('[name="emprunteur_revenu_nature_' + key_repeat + '"]').val() == 'Allocation familiale') {
                $('[name="alloc_fam1"]').val($(this).val());
            }
        });

        $('[name*="coemprunteur_revenu_montant_"],[name*="coemprunteur_revenu_montant_"]').each(function () {
            var key_repeat = $(this).attr('name').replace('coemprunteur_revenu_montant_', '');
            if ($('[name="coemprunteur_revenu_nature_' + key_repeat + '"]').val() == 'Autres') {
                $('[name="revenu_sup2"]').val($(this).val());
            }
            if ($('[name="coemprunteur_revenu_nature_' + key_repeat + '"]').val() == 'Allocation familiale') {
                $('[name="alloc_fam2"]').val($(this).val());
            }
        });

        this.hiddenFields();

        this.checkRegNat();
    }

    select() {
        $('select').not('.not_chosen').not('.elem-countries').chosen({ disable_search_threshold: 15 });
    }

    flagStrapJs() {
        $('.flagstrap').each(function () {
            var elem = $(this).parents('.select-elem').find('.elem-countries');
            var flag = $(this);
            var attr = $(this).attr('name');


            if (typeof attr !== typeof undefined && attr !== false) {
                $(this).flagStrap({
                    buttonSize: "btn-sm",
                    buttonType: "btn-info",
                    labelMargin: "10px",
                    scrollable: false,
                    inputName: elem.attr('name'),
                    scrollableHeight: "350px",
                    placeholder: {
                        value: "",
                        text: "Choisir un pays"
                    }
                });
            } else {
                $(this).flagStrap({
                    countries: {
                        "AFG": "Afghanistan",
                        "ALB": "Albanie",
                        "DZA": "Algérie",
                        "AND": "Andorre",
                        "AGO": "Angola",
                        "AIA": "Anguilla",
                        "ATG": "Antigua-et-Barbuda",
                        "ARG": "Argentine",
                        "ARM": "Arménie",
                        "ABW": "Aruba",
                        "AUS": "Australie",
                        "AUT": "Autriche",
                        "AZE": "Azerbaïdjan",
                        "BHS": "Bahamas",
                        "BHR": "Bahreïn",
                        "BGD": "Bangladesh",
                        "BRB": "Barbade",
                        "BLR": "Biélorussie",
                        "BEL": "Belgique",
                        "BLZ": "Belize",
                        "BEN": "Benin",
                        "BMU": "Bermudes",
                        "BTN": "Bhoutan",
                        "BOL": "Bolivie",
                        "BIH": "Bosnie-Herzégovine",
                        "BWA": "Botswana",
                        "BRA": "Brésil",
                        "BGR": "Bulgarie",
                        "BFA": "Burkina Faso",
                        "BDI": "Burundi",
                        "KHM": "Cambodge",
                        "CMR": "Cameroune",
                        "CAN": "Canada",
                        "CPV": "Cap-Vert",
                        "CYM": "Îles Caïmans",
                        "CAF": "République centrafricaine",
                        "TCD": "Chad",
                        "CHL": "Chili",
                        "CHN": "Chine",
                        "COL": "Colombie",
                        "COM": "Comores",
                        "COG": "Congo",
                        "COD": "République démocratique du Congo",
                        "COK": "Îles Cook",
                        "CRI": "Costa Rica",
                        "CIV": "Côte d'Ivoire",
                        "HRV": "Croatie",
                        "CUB": "Cuba",
                        "CUW": "Curaçao",
                        "CYP": "Chypre",
                        "CZE": "République tchèque",
                        "DNK": "Danemark",
                        "DJI": "Djibouti",
                        "DMA": "Dominique",
                        "DOM": "République dominicaine",
                        "ECU": "Équateur",
                        "EGY": "Égypte",
                        "SLV": "Salvador",
                        "GNQ": "Guinée équatoriale",
                        "ERI": "Érythrée",
                        "EST": "Estonie",
                        "ETH": "Éthiopie",
                        "FLK": "Îles Malouines",
                        "FRO": "Îles Féroé",
                        "FJI": "Fidji",
                        "FIN": "Finlande",
                        "FRA": "France",
                        "GF": "Guyane",
                        "PYF": "Polynésie française",
                        "ATF": "Terres australes et antarctiques françaises",
                        "GAB": "Gabon",
                        "GMB": "Gambie",
                        "GEO": "Georgie",
                        "DEU": "Allemagne",
                        "GHA": "Ghana",
                        "GIB": "Gibraltar",
                        "GR": "Grêce",
                        "GRL": "Groenland",
                        "GRD": "Grenade",
                        "GLP": "Guadeloupe",
                        "GUM": "Guam",
                        "GTM": "Guatemala",
                        "GGY": "Guernesey",
                        "GN": "Guinée",
                        "GNB": "Guinée-Bissau",
                        "GUY": "Guyana",
                        "HTI": "Haïti",
                        "HMD": "Îles Heard-et-MacDonald",
                        "VAT": "Vatican",
                        "HND": "Honduras",
                        "HKG": "Hong Kong",
                        "HUN": "Hongrie",
                        "ISL": "Islande",
                        "IND": "Inde",
                        "IDN": "Indonésie",
                        "IRN": "Iran",
                        "IRQ": "Irak",
                        "IRL": "Irlande",
                        "IMN": "Île de Man",
                        "ISR": "Isra" + "&euml;" + "l",
                        "ITA": "Italie",
                        "JAM": "Jamaïque",
                        "JPN": "Japon",
                        "JEY": "Jersey",
                        "JOR": "Jordanie",
                        "KAZ": "Kazakhstan",
                        "KEN": "Kenya",
                        "KIR": "Kiribati",
                        "PRK": "Corée du Nord",
                        "KOR": "Corée du Sud",
                        "KWT": "Koweït",
                        "KGZ": "Kirghizistan",
                        "LAO": "Laos",
                        "LVA": "Lettonie",
                        "LBN": "Liban",
                        "LSO": "Lesotho",
                        "LBR": "Liberia",
                        "LBY": "Libye",
                        "LIE": "Liechtenstein",
                        "LTU": "Lituanie",
                        "LUX": "Luxembourg",
                        "MAC": "Macao",
                        "MKD": "Macédoine",
                        "MDG": "Madagascar",
                        "MWI": "Malawi",
                        "MYS": "Malaisie",
                        "MDV": "Maldives",
                        "MLI": "Mali",
                        "MLT": "Malte",
                        "MHL": "Îles Marshall",
                        "MTQ": "Martinique",
                        "MRT": "Mauritanie",
                        "MUS": "Maurice",
                        "MYT": "Mayotte",
                        "MEX": "Mexique",
                        "FSM": "États fédérés de Micronésie",
                        "MDA": "Moldavie",
                        "MCO": "Monaco",
                        "MNG": "Mongolie",
                        "MNE": "Monténégro",
                        "MSR": "Montserrat",
                        "MAR": "Maroc",
                        "MOZ": "Mozambique",
                        "MMR": "Birmanie",
                        "NAM": "Namibie",
                        "NRU": "Nauru",
                        "NPL": "Népal",
                        "NLD": "Pays-Bas",
                        "NCL": "Nouvelle-Calédonie",
                        "NZL": "Nouvelle-Zélande",
                        "NIC": "Nicaragua",
                        "NER": "Niger",
                        "NGA": "Nigeria",
                        "NIU": "Niue",
                        "NFK": "Île Norfolk",
                        "MNP": "Îles Mariannes du Nord",
                        "NOR": "Norvège",
                        "OMN": "Oman",
                        "PAK": "Pakistan",
                        "PLW": "Palaos",
                        "PSE": "Territoires palestiniens occupés",
                        "PAN": "Panama",
                        "PNG": "Papouasie-Nouvelle-Guinée",
                        "PRY": "Paraguay",
                        "PER": "Pérou",
                        "PHL": "Philippines",
                        "PCN": "Îles Pitcairn",
                        "POL": "Pologne",
                        "PRT": "Portugal",
                        "PRI": "Porto Rico",
                        "QAT": "Qatar",
                        "REU": "Réunion",
                        "ROU": "Roumanie",
                        "RUS": "Russie",
                        "RWA": "Rwanda",
                        "SHN": "Sainte-Hélène, Ascension et Tristan da Cunha",
                        "KNA": "Saint-Christophe-et-Niévès",
                        "LCA": "Sainte-Lucie",
                        "MAF": "Saint-Martin",
                        "SPM": "Saint-Pierre-et-Miquelon",
                        "VCT": "Saint-Vincent-et-les-Grenadines",
                        "WSM": "Samoa",
                        "SXM": "Saint-Marin",
                        "STP": "Sao Tomé-et-Principe",
                        "SAU": "Arabie Saoudite",
                        "SEN": "Sénégale",
                        "SRB": "Serbie",
                        "SYC": "Seychelles",
                        "SLE": "Sierra Leone",
                        "SGP": "Singapour",
                        "SVK": "Slovaquie",
                        "SVN": "Slovénie",
                        "SLB": "Salomon",
                        "SOM": "Somalie",
                        "ZAF": "Afrique du Sud",
                        "SGS": "Géorgie du Sud-et-les Îles Sandwich du Sud",
                        "SSD": "Soudan du Sud",
                        "ESP": "Espagne",
                        "LKA": "Sri Lanka",
                        "SDN": "Soudan",
                        "SUR": "Suriname",
                        "SWZ": "Swaziland",
                        "SWE": "Suède",
                        "CHE": "Suisse",
                        "SYR": "Syrie",
                        "TJK": "Tadjikistan",
                        "TZA": "Tanzanie",
                        "THA": "Thaïlande",
                        "TLS": "Timor oriental",
                        "TGO": "Togo",
                        "TKL": "Tokelau",
                        "TON": "Tonga",
                        "TTO": "Trinité-et-Tobago",
                        "TUN": "Tunisie",
                        "TUR": "Turquie",
                        "TKM": "Turkménistan",
                        "TCA": "Îles Turks-et-Caïcos",
                        "TUV": "Tuvalu",
                        "UGA": "Ouganda",
                        "UKR": "Ukraine",
                        "ARE": "Émirats arabes unis",
                        "GBR": "Royaume-Uni",
                        "USA": "États-Unis",
                        "UMI": "Îles mineures éloignées des États-Unis",
                        "URY": "Uruguay",
                        "UZB": "Ouzbékistan",
                        "VUT": "Vanuatu",
                        "VEN": "Venezuela",
                        "VNM": "Viêt Nam",
                        "VGB": "Îles Vierges britanniques",
                        "VIR": "Îles Vierges des États-Unis",
                        "WLF": "Wallis-et-Futuna",
                        "ESH": "Sahara occidental",
                        "YEM": "Yémen",
                        "ZMB": "Zambie",
                        "ZWE": "Zimbabwe"
                    },
                    buttonSize: "btn-sm",
                    buttonType: "btn-info",
                    labelMargin: "10px",
                    scrollable: false,
                    inputName: elem.attr('name'),
                    scrollableHeight: "350px",
                    placeholder: {
                        value: "",
                        text: "Choisir un pays"
                    }
                });
            }


            $(this).parent().find('.dropdown-toggle').click(function () {
                if (!$(this).hasClass('active')) {
                    $(this).addClass('active');
                    $(this).next().addClass('active');
                }
                else if ($(this).hasClass('active')) {
                    $(this).removeClass('active');
                    $(this).next().removeClass('active');
                }

                var value = flag.find('select').children("option[selected=selected]").val();
                flag.parents('.simform-elem').find('.elem-countries').val(value);
            });


            $(this).parent().find('.dropdown-menu').find('a').click(function () {
                $(this).parents('.dropdown-menu').removeClass('active');
                $(this).parents('.dropdown-menu').prev().removeClass('active');
                var value = flag.find('select').children("option[selected=selected]").val();
                flag.parents('.simform-elem').find('.elem-countries').val(value);
            });


            var value = flag.find('select').children("option[selected=selected]").val();
            flag.parents('.simform-elem').find('.elem-countries').val(value);
        });
    }

    mask() {
        $('[data-format]').each(function () {
            var formated = $(this).data('format');
            var placeholder = '';
            if ($(this).attr('placeholder').length) {
                placeholder = $(this).attr('placeholder');
            }
            $(this).mask(formated, {placeholder: placeholder});
        });
    }

    dateField() {
        var _this = this;
        $('.simform-date').each(function () {
            if (!$(this).parent().hasClass('groups-elem-template')) {
                var elem_date = $(this).find('input[type="text"]');
                $(this).find('input[type="text"]').Zebra_DatePicker({
                    format: 'd/m/Y',
                    view: 'years',
                    days: ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'],
                    days_abbr: true,
                    show_select_today: 'Aujourd\'hui',
                    lang_clear_date: 'Effacer',
                    first_day_of_week: 1,
                    icon_position: 'left',
                    months: ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Aout', 'Septembre', 'Octobre', 'Novembre', 'Décembre'],
                    onClose: function onClose(view, elements) {
                        _this.validationSingle(elem_date);
                    }
                });
            }
        });
    }

    copyField() {
        var list_copy_field = '';
        $('[name^="copy_"],[name*="copy_"]').each(function (i) {
            if (i !== 0) {
                list_copy_field += ',';
            }
            var copy_name = $(this).attr('name');
            list_copy_field += '[name="' + copy_name.replace('copy_', '') + '"]';
        });

        $(list_copy_field).blur(function () {
            var name = $(this).attr('name');
            var copy = $('[name="copy_' + name + '"]');

            if (copy.length) {
                copy.val($(this).val());
            }

            if (copy.hasClass('elem-select')) {
                copy.trigger('chosen:updated');
            }
        });

        $(list_copy_field).change(function () {
            var name = $(this).attr('name');
            var copy = $('[name="copy_' + name + '"]');

            if (copy.length) {
                copy.val($(this).val());
            }

            if (copy.hasClass('elem-select')) {
                copy.trigger('chosen:updated');
            }
        });

        $(list_copy_field).each(function () {
            var name = $(this).attr('name');
            var copy = $('[name="copy_' + name + '"]');


            if (copy.length) {
                copy.val($(this).val());
            }

            if (copy.hasClass('elem-select')) {
                copy.trigger('chosen:updated');
            }
        });
    }

    navSection() {
        var _this = this;

        $('.simform').on('keyup keypress', function (e) {
            var keyCode = e.keyCode || e.which;
            if (keyCode === 13) {
                e.preventDefault();
                e.stopPropagation();
                var check_step = JSON.parse(localStorage.getItem('steps'));
                _this.validation();
            }
        });

        $('button[data-type="prev"]').click(function (e) {
            e.preventDefault();
            $('html,body').animate({scrollTop: 0}, 1000);

            localStorage.setItem('steps', JSON.stringify({
                step: $(e.target).data('step')
            }));

            $('.simform-step').removeClass('active');
            $('.simform-step-' + $(e.target).data('step')).addClass('active');


            if ($(this).data('step') == '1') {
                $('.multistepform__intro').show();
                $('.simform>.simform-html').show();
            }
            _this.checkStep();
        });

        $('button[data-type="next"]').click(function (e) {
            e.preventDefault();

            if (!_this.validation()) {
                var current_step = parseInt($(e.target).data('step'));
                var request_token = localStorage.getItem('request_token');
                var form_data = $(".simform").serialize();

                localStorage.setItem('steps', JSON.stringify({ step: (current_step + 1) }));
                _this.storageStep(current_step);
                var group_data = {};

                $('.form-groups').each(function () {
                    var group_name = $(this).data('group-id');
                    group_data[group_name] = {};
                    $(this).find('.groups-elem:not(.groups-elem-template)').each(function (gr_el) {
                        group_data[group_name][gr_el] = {};
                        $(this).find('.simform-elem').each(function (i) {
                            var el = $(this).find('input:not(.chosen-search-input):not([type="radio"]),select,input[type="radio"]:checked');
                            if (el.parents('.simform-elem').hasClass('simform-radio')) {
                                group_data[group_name][gr_el][el.parents('.simform-radio').find('input:checked').attr('name')] = [el.parents('.simform-radio').find('input:checked').val()];
                            } else {
                                group_data[group_name][gr_el][el.attr('name')] = [el.val()];
                            }
                        });
                    });
                });

                // Sauvegarde dans la base de données via AJAX
                jQuery.ajax({
                    type: "POST",
                    url: ajaxurl.ajaxurl,
                    data: {
                        action: "simform_save_step",
                        name: form_data,
                        group: group_data,
                        request_token: request_token,
                        current_step: current_step,
                    },
                    success: function (response) {
                        console.log('Données enregistrées : ', response);
                    },
                    error: function (errorThrown) {
                        console.error('Erreur AJAX : ', errorThrown);
                    }
                });

                if ($(this).data('send-mail') == '1') {
                    e.preventDefault();
                    var form_data = $(".simform").serialize();

                    jQuery.ajax({
                        type: "POST",
                        url: ajaxurl.ajaxurl,
                        data: {
                            action: "simform_pre_submit",
                            name: form_data,
                        },
                        success: function (data) {
                            console.log(data);
                        },
                        error: function (errorThrown) {
                            alert(errorThrown);
                        }
                    });
                }

                if ($(this).data('step') == '1') {
                    $('.multistepform__intro').hide();
                    $('.simform>.simform-html').hide();
                }

                _this.checkStep();

                if ($(this).data('step') == '3') {
                    _this.checkRegNat();
                }

                if ($(e.target).attr('id') == 'valid-form') {
                    _this.copy_crm();
                    $('#js--loader-form').addClass('active');
                    setTimeout(function () {
                        $('.multistepform__submit input').trigger('click');
                    }, 1000);
                } else {
                    $('html,body').animate({scrollTop: 0}, 1000);
                    $('.simform-step-' + current_step).removeClass('active');
                    $('.simform-step-' + (current_step + 1)).addClass('active');
                }
            }
        });

        $('button[data-type="send"]').click(function (e) {
            e.preventDefault();
            if (!_this.validation()) {
                //$('.simform-loader').addClass('active');
                var form_data = $(".simform").serialize();
                var request_token = localStorage.getItem('request_token');
                var group_data = {};

                $('.form-groups').each(function () {
                    var group_name = $(this).data('group-id');
                    group_data[group_name] = {};
                    $(this).find('.groups-elem:not(.groups-elem-template)').each(function (gr_el) {
                        group_data[group_name][gr_el] = {};
                        $(this).find('.simform-elem').each(function (i) {
                            var el = $(this).find('input:not(.chosen-search-input):not([type="radio"]),select,input[type="radio"]:checked');
                            if (el.parents('.simform-elem').hasClass('simform-radio')) {
                                group_data[group_name][gr_el][el.parents('.simform-radio').find('input:checked').attr('name')] = [el.parents('.simform-radio').find('input:checked').val()];
                            } else {
                                group_data[group_name][gr_el][el.attr('name')] = [el.val()];
                            }
                        });
                    });
                });
                var current_step = parseInt($(e.target).data('step'));
                localStorage.setItem('steps', JSON.stringify({ step: (current_step + 1) }));
                _this.storageStep(current_step);

                jQuery.ajax({
                    type: "POST",
                    url: ajaxurl.ajaxurl,
                    data: {
                        action: "simform_submit",
                        name: form_data,
                        group: group_data,
                        request_token: request_token,
                        current_step: current_step,
                        hidden_fields: window.hidden_fields,
                        crm_fields: window.crm_fields,
                    },
                    success: function (data) {
                        console.log(data);
                        location.href = $('[name="redirect_to"]').val();
                    },
                    complete: function (data) {
                        console.log(data);
                    },
                    error: function (errorThrown) {
                        alert(errorThrown);
                        $('.simform-loader').removeClass('active');
                        alert('Erreur lors de l\'envoi de votre demande. Veuillez vérifier que vous êtes bien connecté à Internet.')
                    }
                });
            }
        });
    }

    hiddenFields() {
        $('input[type="radio"],input[type="checkbox"]').change(function () {
            for (var i = 0; i < window.hidden_fields.length; i++) {
                if ($(this).attr('name') == window.hidden_fields[i]['hidden_action']) {
                    var check = window.hidden_fields[i]['hidden_check'].split(',');

                    if ($.inArray($(this).val(), check) !== -1) {
                        $(window.hidden_fields[i]['hidden_display']).show();
                    } else {
                        $(window.hidden_fields[i]['hidden_display']).hide();
                    }
                }
            }
        });

        $('select').change(function () {
            for (var i = 0; i < window.hidden_fields.length; i++) {
                if ($(this).attr('name') == window.hidden_fields[i]['hidden_action']) {
                    var check = window.hidden_fields[i]['hidden_check'].split(',');

                    if ($.inArray($(this).val(), check) !== -1) {
                        if ($(window.hidden_fields[i]['hidden_display']).prop("tagName") == 'INPUT') {
                            $(window.hidden_fields[i]['hidden_display']).prop('checked', true);
                            $(window.hidden_fields[i]['hidden_display']).change();
                        } else {
                            $(window.hidden_fields[i]['hidden_display']).show();
                        }
                    } else {
                        if ($(window.hidden_fields[i]['hidden_display']).prop("tagName") == 'INPUT') {
                            $(window.hidden_fields[i]['hidden_display']).prop('checked', false);
                            $(window.hidden_fields[i]['hidden_display'].replace('-1', '-0')).prop('checked', true);
                            $(window.hidden_fields[i]['hidden_display']).change();
                            $(window.hidden_fields[i]['hidden_display'].replace('-1', '-0')).change();
                        } else {
                            $(window.hidden_fields[i]['hidden_display']).hide();
                        }
                    }
                }
            }
        });
    }

    groups() {
        var _this = this;
        add_group();
        remove_group();
        refactor_template();
        remove_first_add_group();

        function remove_first_add_group() {
            $('.add-groups').bind('click', function () {
                if ($(this).prev().hasClass('groups-elem-template')) {
                    $(this).hide();
                }
            });
        }

        function refactor_template() {
            $('.groups-elem-template').each(function () {
                $(this).find('input:not(.chosen-search-input),select').each(function () {
                    if ($(this).attr('id') !== '' && $(this).is('[id]')) {
                        var field_id = $(this).attr('id');
                        $(this).attr('id', field_id);
                        $(this).next().attr('for', field_id);
                    }
                });
            });
        }

        function reload_hidden_fields(new_group) {
            new_group.find('input[type="radio"],input[type="checkbox"]').change(function () {
                for (var i = 0; i < window.hidden_fields.length; i++) {
                    if ($(this).attr('name') == window.hidden_fields[i]['hidden_action']) {
                        var check = window.hidden_fields[i]['hidden_check'].split(',');

                        if ($.inArray($(this).val(), check) !== -1) {
                            $(window.hidden_fields[i]['hidden_display']).show();
                        } else {
                            $(window.hidden_fields[i]['hidden_display']).hide();
                        }
                    }
                }
            });

            new_group.find('select').change(function () {
                for (var i = 0; i < window.hidden_fields.length; i++) {
                    var select_name = $(this).attr('name').replace(/\d/g, '').slice(0, -1);
                    if (select_name == window.hidden_fields[i]['hidden_action']) {
                        var check = window.hidden_fields[i]['hidden_check'].split(',');

                        if (window.hidden_fields[i]['hidden_display_none'] == '') {
                            if ($.inArray($(this).val(), check) !== -1) {
                                if ($(window.hidden_fields[i]['hidden_display']).prop("tagName") == 'INPUT') {
                                    $(window.hidden_fields[i]['hidden_display']).prop('checked', true);
                                    $(window.hidden_fields[i]['hidden_display']).change();
                                } else {
                                    $(this).parents('.groups-elem').find(window.hidden_fields[i]['hidden_display']).show();
                                }
                            } else {
                                if ($(window.hidden_fields[i]['hidden_display']).prop("tagName") == 'INPUT') {
                                    $(window.hidden_fields[i]['hidden_display']).prop('checked', false);
                                    $(window.hidden_fields[i]['hidden_display'].replace('-1', '-0')).prop('checked', true);
                                    $(window.hidden_fields[i]['hidden_display']).change();
                                    $(window.hidden_fields[i]['hidden_display'].replace('-1', '-0')).change();
                                } else {
                                    $(this).parents('.groups-elem').find(window.hidden_fields[i]['hidden_display']).hide();
                                }
                            }
                        } else {
                            if ($.inArray($(this).val(), check) !== -1) {
                                if ($(window.hidden_fields[i]['hidden_display_none']).prop("tagName") == 'INPUT') {
                                    $(window.hidden_fields[i]['hidden_display_none']).prop('checked', true);
                                    $(window.hidden_fields[i]['hidden_display_none']).change();
                                } else {
                                    $(this).parents('.groups-elem').find(window.hidden_fields[i]['hidden_display_none']).hide();
                                }
                            } else {
                                if ($(window.hidden_fields[i]['hidden_display_none']).prop("tagName") == 'INPUT') {
                                    $(window.hidden_fields[i]['hidden_display_none']).prop('checked', false);
                                    $(window.hidden_fields[i]['hidden_display_none'].replace('-1', '-0')).prop('checked', true);
                                    $(window.hidden_fields[i]['hidden_display_none']).change();
                                    $(window.hidden_fields[i]['hidden_display_none'].replace('-1', '-0')).change();
                                } else {
                                    $(this).parents('.groups-elem').find(window.hidden_fields[i]['hidden_display_none']).show();
                                }
                            }
                        }
                    }
                }
            });
        }

        function add_group() {
            $('.add-groups').bind('click', function () {
                var groups = $(this).parents('.form-groups');
                var max_grp = groups.data('grp-max');
                var grp_n = groups.find('.groups-elem:not(.groups-elem-template)').length;

                var groups_html = groups.find('.groups-elem-template').html();

                if (grp_n < max_grp) {
                    groups.append('<div class="groups-elem groups-elem-' + (grp_n) + '">' + groups_html + '</div>');

                    groups.find('.groups-elem-' + (grp_n)).find('input:not(.chosen-search-input),select').each(function () {
                        var field_name = $(this).attr('name').replace('_template', '');
                        $(this).attr('name', field_name + '_' + (grp_n));

                        if ($(this).parent().find('.chosen-container').length) {
                            $(this).parent().find('.chosen-container').remove();
                            $(this).chosen({disable_search_threshold: 15});
                        }

                        if ($(this).hasClass('elem-date')) {
                            var elem_date = $(this);
                            $(this).Zebra_DatePicker({
                                format: 'd/m/Y',
                                view: 'years',
                                days: ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'],
                                days_abbr: true,
                                show_select_today: 'Aujourd\'hui',
                                lang_clear_date: 'Effacer',
                                first_day_of_week: 1,
                                icon_position: 'left',
                                months: ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Aout', 'Septembre', 'Octobre', 'Novembre', 'Décembre'],
                                onClose: function onClose(view, elements) {
                                    if ($(this).parents('p').data('mandatory') == 'true') {
                                        _this.validationSingle(elem_date);
                                    }
                                }
                            });
                        }

                        if ($(this).attr('id') !== '' && $(this).is('[id]')) {
                            var field_id = $(this).attr('id').replace('_template', '');
                            $(this).attr('id', field_id + '_' + (grp_n));
                            $(this).next().attr('for', field_id + '_' + (grp_n));
                        }
                    });

                    if (!groups.find('.groups-elem-' + (grp_n)).find('.remove-groups').length) {
                        groups.find('.groups-elem-' + (grp_n)).append('<button type="button" class="btn-form add-groups">' + groups.find('.groups-elem-template+.add-groups').text() + '</button>');
                        groups.find('.groups-elem-' + (grp_n)).append('<button type="button" class="btn-form remove-groups">Supprimer</button>');
                    }

                    if (grp_n == 1) {
                        if (!groups.find('.groups-elem-0').find('.remove-groups').length) {
                            groups.find('.groups-elem-0').append('<button type="button" class="btn-form remove-groups">Supprimer</button>');
                        }
                    }
                    reload_hidden_fields(groups.find('.groups-elem-' + (grp_n)));
                } else {
                    alert('Vous avez atteint le nombre maximum de champs');
                }

                $('.add-groups').unbind('click');
                $('.remove-groups').unbind('click');

                add_group();
                remove_group();
                remove_first_add_group();


                $('[name*="emprunteur_revenu_montant_"],[name*="emprunteur_revenu_montant_"]').blur(function () {
                    var key_repeat = $(this).attr('name').replace('emprunteur_revenu_montant_', '');
                    if ($('[name="emprunteur_revenu_nature_' + key_repeat + '"]').val() == 'Autres') {
                        $('[name="revenu_sup1"]').val($(this).val());
                    }
                    if ($('[name="emprunteur_revenu_nature_' + key_repeat + '"]').val() == 'Allocation familiale') {
                        $('[name="alloc_fam1"]').val($(this).val());
                    }
                });

                $('[name*="coemprunteur_revenu_montant_"],[name*="coemprunteur_revenu_montant_"]').blur(function () {
                    var key_repeat = $(this).attr('name').replace('coemprunteur_revenu_montant_', '');
                    if ($('[name="coemprunteur_revenu_nature_' + key_repeat + '"]').val() == 'Autres') {
                        $('[name="revenu_sup2"]').val($(this).val());
                    }
                    if ($('[name="coemprunteur_revenu_nature_' + key_repeat + '"]').val() == 'Allocation familiale') {
                        $('[name="alloc_fam2"]').val($(this).val());
                    }
                });
            });
        }

        function remove_group() {
            $('.remove-groups').bind('click', function () {
                var groups = $(this).parents('.form-groups');
                var group_elem = $(this).parent('.groups-elem');
                var min_grp = groups.data('grp-min');
                var grp_n = groups.find('.groups-elem:not(.groups-elem-template)').length;

                if (grp_n > min_grp) {
                    group_elem.remove();
                } else {
                    alert('Vous avez atteint le nombre minimum de champs');
                }
            });
        }
    }

    checkStep() {
        var check_step = JSON.parse(localStorage.getItem('steps'));
        console.log(check_step);

        if (localStorage.getItem("steps") === null) {
            $('.simform>.simform-html').show();
            $('.simform-step-1').addClass('active');
            $('.simform-steps__item:nth-child(1)').addClass('active');
        } else {
            if (check_step.step == 5) {
                check_step.step = 4;
            }
            if (check_step.step != 1) {
                $('.simform>.simform-html').hide();
                $('.multistepform__intro').hide();
            }
            //HIDE HTML ELEMENT BEFORE FORM
            $('.simform-step-' + check_step.step).addClass('active');
            $('.simform-steps__item').removeClass('active','valid');
            for (var i = 0; i < check_step.step; i++) {
                $('.simform-steps__item:nth-child(' + i + ')').addClass('valid');
            }
            $('.simform-steps__item:nth-child(' + check_step.step + ')').addClass('active');

            for (i = 1; i < check_step.step; i++) {
                this.storageFill(i);
            }
        }
    }

    layout() {
        var _this = this;

        $('.check_rgpd').click(function () {
            if ($(this).find('input[type="checkbox"]:checked').length) {
                $(this).find('input[type="checkbox"]').val('1');
                $('input[name="rgpd_consentement1_mail"]').val('Oui');
                $('input[name="rgpd_consentement1"]').val('1');
            } else {
                $(this).find('input[type="checkbox"]').val('0');
                $('input[name="rgpd_consentement1_mail"]').val('Non');
                $('input[name="rgpd_consentement1"]').val('0');
            }
        });

        $('.simform').find('[aria-required="true"]').focusout(function (e) {
            _this.validationSingle($(e.target));
        });

        $('.simform').find('select[aria-required="true"]').change(function (e) {
            _this.validationSingle($(e.target));
        });

        $('select[name=coemprunteur-profession]').change(function () {
            if ($(this).val().replace(/\s/g, '') == 'Maitressedemaison') {
                $('.revenu-coemprunteur,.repeatfields-1').hide();
                $('#adresses-coemprunteur .multistepform__blocAdress:nth-child(2)').hide();
            } else {
                $('.revenu-coemprunteur,.repeatfields-1').show();
                $('#adresses-coemprunteur .multistepform__blocAdress:nth-child(2)').show();
            }
        });

        $('#logo,.menu-item a').click(function (e) {
            e.preventDefault();
            //localStorage.removeItem('steps');
            localStorage.clear();

            location.href = $(this).attr('href');
        });

        $('#js--resume-edit').click(function (e) {
            e.preventDefault();
            $('.simulation--popup').addClass('active');
            $('body').addClass('stop-scroll');
        });

        $('#js--resume-close').click(function () {
            $('.simulation--popup').removeClass('active');
            $('body').removeClass('stop-scroll');
        });
    }

    custom() {
        var _this = this;

        $('#js-copyaddress').click(function (e) {
            e.preventDefault();
            $('[name="coemprunteur_rue"]').val($('[name="emprunteur_rue"]').val());
            $('[name="coemprunteur_numero"]').val($('[name="emprunteur_numero"]').val());
            $('[name="coemprunteur_boite"]').val($('[name="emprunteur_boite"]').val());
            $('[name="coemprunteur_code_postal"]').val($('[name="emprunteur_code_postal"]').val());
            $('[name="coemprunteur_ville"]').val($('[name="emprunteur_ville"]').val());
            $('[name="coemprunteur_pays"]').val($('[name="emprunteur_pays"]').val());
            $('[name="coemprunteur_pays"]').trigger("chosen:updated");
            $('[name="coemprunteur_domicilie"]').val($('[name="emprunteur_domicilie"]').val());
        });
    }

    checkRegNat() {
        var _this = this;

        if ($('[name="emprunteur_pays"]').val() == "BEL") {
            $('[name="emprunteur_reg_nat"]').attr('aria-required', 'true');
            $('[name="emprunteur_reg_nat"]').parent('.simform-elem').attr('data-mandatory', 'true');

        } else {
            $('[name="emprunteur_reg_nat"]').attr('aria-required', 'false');
            $('[name="emprunteur_reg_nat"]').parent('.simform-elem').attr('data-mandatory', 'false');
        }

        if ($('[name="coemprunteur_pays"]').val() == "BEL") {
            $('[name="coemprunteur_reg_nat"]').attr('aria-required', 'true');
            $('[name="coemprunteur_reg_nat"]').parent('.simform-elem').attr('data-mandatory', 'true');
        } else {
            $('[name="coemprunteur_reg_nat"]').attr('aria-required', 'false');
            $('[name="coemprunteur_reg_nat"]').parent('.simform-elem').attr('data-mandatory', 'false');
        }
        $('.simform').find('[name="emprunteur_reg_nat"][aria-required="true"]').focusout(function (e) {
            _this.validationSingle($(e.target));
        });
        $('.simform').find('[name="coemprunteur_reg_nat"][aria-required="true"]').focusout(function (e) {
            _this.validationSingle($(e.target));
        });
    }

    validationSingle(elem) {
        var el = void 0;
        var error = false;
        var errorText = '<p class="simform-error">Ce champ est obligatoire</p>';
        var errorEmail = '<p class="simform-error">Le format de l\'email est incorrect</p>';

        el = elem;
        if (el.attr('type') === 'text' || el.attr('type') === 'phone') {
            if (el.val() === '') {
                el.closest('.simform-elem').append(errorText);
                el.closest('.simform-elem').addClass('error-active');
                el.closest('.simform-elem').removeClass('field-valid');
                error = true;
            } else {
                el.closest('.simform-elem').find('.simform-error').remove();
                el.closest('.simform-elem').removeClass('error-active');
                el.closest('.simform-elem').addClass('field-valid');
            }
        } else if (el.attr('type') === 'number') {
            var reg_number = /^\d+$/;

            if (!reg_number.test(el.val()) || parseInt(el.val()) == 0) {
                el.closest('.simform-elem').append(errorText);
                el.closest('.simform-elem').addClass('error-active');
                el.closest('.simform-elem').removeClass('field-valid');
                error = true;
            } else {
                el.closest('.simform-elem').find('.simform-error').remove();
                el.closest('.simform-elem').removeClass('error-active');
                el.closest('.simform-elem').addClass('field-valid');
            }
        } else if (el.attr('type') === 'email') {
            var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if (!re.test(el.val())) {
                el.closest('.simform-elem').append(errorEmail);
                el.closest('.simform-elem').addClass('error-active');
                error = !re.test(el.val());
            } else {
                el.closest('.simform-elem').find('.simform-error').remove();
                el.closest('.simform-elem').removeClass('error-active');
                el.closest('.simform-elem').addClass('field-valid');
            }
        } else if (el.prop("tagName") === 'SELECT') {
            if (el.val() === '--' || el.val() === '') {
                error = true;
                el.parent().find('.chosen-container-single').addClass('error');
            } else {
                el.parent().find('.chosen-container-single').removeClass('error');
            }
        }
    }

    validation() {
        var el = void 0;
        var error = false;
        var errorText = '<p class="simform-error">Ce champ est obligatoire</p>';
        var errorEmail = '<p class="simform-error">Le format de l\'email est incorrect</p>';

        var i = 0;
        var first_alert = '';
        $('.simform-step.active input:not(.chosen-search-input)').each(function () {
            el = $(this);
            if (el.attr('aria-required') == 'true') {
                if (el.attr('type') === 'text' || el.attr('type') === 'phone') {
                    if (el.is(":visible") && el.val() === '') {
                        el.closest('.simform-elem').append(errorText);
                        el.closest('.simform-elem').addClass('error-active');
                        error = true;

                        if (first_alert === '') {
                            $('html,body').animate({scrollTop: el.closest('.simform-elem').position().top}, 1000);

                            first_alert = 'load';
                        }
                    } else {
                        el.closest('.simform-elem').find('.simform-error').remove();
                    }
                } else if (el.attr('type') === 'number') {
                    var reg_number = /^\d+$/;

                    if ((!reg_number.test(el.val()) || parseInt(el.val()) == 0) && el.is(":visible")) {
                        el.closest('.simform-elem').append(errorText);
                        el.closest('.simform-elem').addClass('error-active');
                        el.closest('.simform-elem').removeClass('field-valid');
                        error = true;

                        if (first_alert === '') {
                            $('html,body').animate({scrollTop: el.closest('.simform-elem').position().top}, 1000);

                            first_alert = 'load';
                        }
                    } else {
                        el.closest('.simform-elem').find('.simform-error').remove();
                        el.closest('.simform-elem').removeClass('error-active');
                        el.closest('.simform-elem').addClass('field-valid');
                    }
                } else if (el.attr('type') === 'email') {
                    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                    if (!re.test(el.val())) {
                        el.closest('.simform-elem').append(errorEmail);
                        el.closest('.simform-elem').addClass('error-active');
                        error = !re.test(el.val());
                    } else {
                        el.closest('.simform-elem').find('.simform-error').remove();
                        el.closest('.simform-elem').removeClass('error-active');
                        el.closest('.simform-elem').addClass('field-valid');
                    }
                } else {
                    //console.log(el.prop("tagName"));
                    //el.closest('.simform-elem').find('.simform-error').remove();
                }
                //console.log(error);
                //console.log(el.parent().attr("class"));
            }
            i++;
        });
        $('.simform-step.active .elem-select').each(function () {
            el = $(this);
            if (el.attr('aria-required') == 'true') {
                if ((el.val() === '--' || el.val() === '') && el.is(":visible")) {
                    error = true;
                    el.parent().find('.chosen-container-single').addClass('error');
                } else {
                    el.parent().find('.chosen-container-single').removeClass('error');
                }
                //console.log(el.prop("tagName"));
                //console.log(el.attr("class"));
            }
            i++;
        });

        return error;
    }
}

import $ from 'jquery';

class Simulation {
    constructor(params) {
        this.params = params;
        this.element = this.params.element;
        this.data = this.params.datas;

        // DOM Elements
        this.inputFormatElement = document.getElementById('simulation__result');
        this.amountElement = document.querySelector('[data-bind="amount"]');
        this.taeg = $('[data-bind="taeg"]');
        this.debitValue = $('[data-bind="debitValue"]');
        this.monthlyElement = $('.simulation__resultPrice').find('[data-bind="monthlyValue"]');
        this.monthElement = $('.simulation__resultResume').find('[data-bind="monthValue"]');
        this.totalAmountElement = $('.simulation__resultResume').find('[data-bind="totalAmount"]');
        this.$creditTypeForm = $('.simulation__typeForm');
        this.$creditTypeFormBox = $('.simulation__types__item');
        this.activeClass = 'simulation__timeItem--active';

        this.sliderElement = document.getElementById('simulation__slider');
        this.min = Number(this.sliderElement.getAttribute('data-min'));
        this.max = Number(this.sliderElement.getAttribute('data-max'));

        this.currentCreditID = null;
        this.amount = null;
        this.month = null;
        this.getTimeItemElement = function () {
            return $('.simulation__timeItem');
        };

        this.init();
    }

    // Initialize the simulation
    init() {
        this.slider();
        this.setupEvents();
    }

    calcMens(mnt, dur, taeg) {
        const dur12 = dur / 12;
        const t100 = taeg / 100;
        const buf1 = Math.pow(1 + t100, 1 / 12);
        const buf2 = Math.pow(1 / (1 + t100), dur12);
        const mens = (mnt * (buf1 - 1)) / (1 - buf2);
        return Math.floor(mens * 100) / 100;
    }

    setupEvents() {
        this.handleInputEvents();
        this.$creditTypeForm.change((e) => this.loadCredit(e));
        this.$creditTypeFormBox.click((e) => this.loadCreditBox(e));
        $('.simulation__types__item:first-child').click();
        this.setupMonthBehavior();
        $('.simulation__goLink').click(this.handleGoLink.bind(this));
    }

    handleInputEvents() {
        const $less = $('.simulation__resultLess');
        const $value = $('.simulation__value');
        const $plus = $('.simulation__resultPlus');
        const interval = 500;

        $less.click(() => {
            const newVal = parseInt($value.val()) - interval;
            $value.val(newVal);
            this.sliderElement.noUiSlider.set(newVal);
        });

        $plus.click(() => {
            const newVal = parseInt($value.val()) + interval;
            $value.val(newVal);
            this.sliderElement.noUiSlider.set(newVal);
        });
    }

    loadCredit() {
        this.currentCreditID = $('.simulation__typeForm').val();
        const credit = this.data.find((credit) => parseInt(credit.id) === parseInt(this.currentCreditID));
        if (credit) {
            this.getMinMax(credit);
            this.updateSliderOptions();
            this.loadMonth(credit);
            this.refreshData();
        }
    }

    loadCreditBox(elem) {
        this.currentCreditID = $(elem.target).data('value');
        $(elem.target).attr('data-selected','true');
        const credit = this.data.find((credit) => parseInt(credit.id) === parseInt(this.currentCreditID));
        if (credit) {
            this.getMinMax(credit);
            this.updateSliderOptions();
            this.loadMonth(credit);
            this.refreshData();
        }
    }

    getMinMax(credit) {
        if (credit && credit.content.length) {
            this.min = credit.content[0].amount;
            this.max = credit.content[credit.content.length - 1].amount;
        }
    }

    loadMonth(credit) {
        const $timeList = $('.simulation__timeList');
        $timeList.empty();

        if (credit && credit.content) {
            credit.content.forEach((entry) => {
                if (parseInt(entry.amount) === parseInt(this.amount)) {
                    entry.timing.forEach((time) => {
                        $timeList.append(this.htmlMonth(time.taeg, time.mois));
                    });
                }
            });
        }

        this.setupMonthBehavior();
    }

    htmlMonth(taeg, time) {
        return `
            <li class="simulation__timeItem">
                <a href="#" class="simulation__timeLink">
                    <span class="bold">
                        <strong data-bind="monthly" data-taeg="${taeg}"></strong>€
                    </span>
                    <span class="simulation__timeMonth">
                        <span data-bind="month">${time}</span> mois
                    </span>
                </a>
            </li>`;
    }

    setupMonthBehavior() {
        var _this = this;
        this.timeItemElement = this.getTimeItemElement();
        this.timeItemElement.last().addClass(this.activeClass);
        this.month = this.timeItemElement.last().find('[data-bind="month"]').text();
        this.taeg.text($('.' + this.activeClass).find('[data-taeg]').attr('data-taeg'));
        this.debitValue.text($('.' + this.activeClass).find('[data-taeg]').attr('data-taeg'));
        this.timeItemElement.click((e) => {
            e.preventDefault();
            this.taeg.text($(e.target).find('[data-taeg]').attr('data-taeg'));
            this.debitValue.text($(e.target).find('[data-taeg]').attr('data-taeg'));
            $('.' + this.activeClass).removeClass(_this.activeClass);
            $(e.target).toggleClass(this.activeClass);
            this.month = $(e.target).find('[data-bind="month"]').text();
            this.refreshData();
        });
    }

    refreshData() {
        this.inputFormatElement.value = `${parseInt(this.amount)}€`;
        this.amountElement.textContent = this.amount;
        this.taeg.text($('.' + this.activeClass).find('[data-taeg]').attr('data-taeg'));
        this.monthlyElement.text(this.calcMens(this.amount, this.month, parseFloat(this.taeg.text())));
        this.monthElement.text(this.month);
        this.totalAmountElement.text((this.monthlyElement.text() * this.month).toFixed(2));

        let _this = this;
        this.getTimeItemElement().each(function () {
            $(this).find('[data-bind="monthly"]').text(_this.calcMens(_this.amount, $(this).find('[data-bind="month"]').text(), $(this).find('[data-taeg]').attr('data-taeg')));
        });
    }

    updateSliderOptions() {
        if (typeof this.sliderElement.noUiSlider !== 'undefined') {
            this.sliderElement.noUiSlider.updateOptions({
                start: 10000,
                connect: [true, false],
                orientation: 'horizontal',
                behaviour: 'tap-drag',
                step: 500,
                tooltips: false,
                range: {
                    min: [Math.round(this.min / 100) * 100, 500],
                    max: [Math.round(this.max / 100) * 100],
                },
                format: wNumb({
                    decimals: 0
                }),
                pips: { // Show a scale with the slider
                    mode: 'positions',
                    values: [0, 25, 50, 75, 100],
                    density: 3,
                    stepped: true
                }
            });
        }
    }

    slider() {
        if (typeof this.sliderElement.noUiSlider === 'undefined') {
            window.noUiSlider.create(this.sliderElement, {
                start: this.max / 2,
                connect: [true, false],
                range: {
                    min: [this.min, 500],
                    max: [this.max],
                },
                step: 500,
            });
        }

        this.sliderElement.noUiSlider.on('update', (values) => {
            this.amount = parseFloat(values[0]);
            this.loadMonth(this.data.find((credit) => parseInt(credit.id) === parseInt(this.currentCreditID)));
            this.refreshData();
        });

        this.inputFormatElement.addEventListener('change', (e)=> {
            return this.sliderElement.noUiSlider.set(e.target.value);
        });
    }

    handleGoLink(e) {
        e.preventDefault();
        let title = '';
        if ($('.simulation__typeForm').length) {
            title = $('.simulation__typeForm').find('option:selected').text();
        } else {
            title = $('.simulation__types__item[data-selected="true"]').text();
        }
        localStorage.setItem('credit', JSON.stringify({
            title: title,
            amount: parseFloat(this.amountElement.textContent),
            taeg: parseFloat(this.taeg.text()),
            month: parseInt(this.month),
            monthly: parseFloat(this.monthlyElement.text()),
        }));
        window.location.href = $(e.currentTarget).attr('href');
    }
}

// Main application initialization
export default class Site {
    constructor() {
        this.init();
    }

    init() {
        this.initializeSimulation();
        this.setupPopupEvents();
    }

    initializeSimulation() {
        const $body = $('body');
        if ($body.find('[data-simulation]').length > 0) {
            new Simulation({
                element: document.querySelector('[data-simulation]'),
                datas: window.dataSim,
            });

            //$body.find('[data-simulation]').find('select').chosen({ disable_search_threshold: 15 });
        }
    }

    setupPopupEvents() {
        $('#js--resume-edit').click(() => {
            $('.simulation--popup').addClass('active');
        });

        $('.simulation__close').click(() => {
            $('.simulation--popup').removeClass('active');
            $('body').removeClass('stop-scroll');
        });
    }
}
